import React, { useContext, useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { HeaderNavigationData } from "./HeaderNavigation.d"
import tw from "twin.macro"
import { NavMenu } from "../../atoms/NavMenu"
import { ToyotaLogo } from "../../atoms/Logo"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import { SearchGlobalDesktop } from "../SearchGlobalDesktop"
import { SearchContext } from "../../../contexts/Search"
import { HeaderContext } from "../../../contexts/Header"
import { Favorites } from "../Favorites"
import RecentlyViewed from "../RecentlyViewed/RecentlyViewed"
import { Banner } from "../Banner"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { css } from "@emotion/react"
import { Popover, POPOVER_ORIGINS } from "../../atoms/Popover"
import { Button } from "../../atoms/Button"
import FavoriteTooltip from "../Favorites/FavoriteTooltip"
import { setShouldShowTooltip } from "../../../contexts/Favorites/actions"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import {
  openFavoritesContactDealerModal,
  closeFavoritesContactDealerModal,
} from "../../../contexts/Contact/actions"
import FavoritesContactDealer from "../ContactDealer/FavoritesContactDealer"
import { ContactContext } from "../../../contexts/Contact"
import { useLocation } from "@reach/router"
import { AnimatePresence, motion } from "framer-motion"
import { Tooltip } from "../../atoms/Tooltip"
import useViewportSize from "../../../hooks/useViewportSize"

enum OPEN_POPOVER {
  LANGUAGE,
}

/**
 * The header visible on every page. Displays the NavMenu component.
 * @author Tyler
 * @returns <HeaderNavigation />
 *
 * @todo - Implement translation logic, currently pulling only english document
 * @todo - Review use of context for menu open state, versus component-level state. (Stu)
 */

const HeaderNavigation: React.FC = () => {
  const { language, _ } = useContext(LanguageContext)
  const [data, setData] = useState(null)

  /* Pull only header menu data from sanityNavigation */
  const headerData: HeaderNavigationData = useStaticQuery(graphql`
    query {
      headerMenu: sanityNavigationHeader(language: { eq: "en" }) {
        ...HeaderNavigationData
      }
      esHeaderMenu: sanityNavigationHeader(language: { eq: "es" }) {
        ...HeaderNavigationData
      }
    }
  `)

  const navMenuRef = useRef(null)
  useOnClickOutside(navMenuRef, () => setOpen(null))

  // State and bindings
  const [mobileView, setMobileView] = useState(null)

  // Search context is required in order to clear search form when the search menu is selected
  const [searchData, dispatch] = useContext(SearchContext)
  // ""toggleMenu" and "open" states live in Context to allow for menu dismissal on search submission
  const { toggleMenu, setToggleMenu, open, setOpen } = useContext(HeaderContext)

  const [favData, favDispatch] = useContext(FavoritesContext)
  const [{ isFavoritesContactDealerModalOpen }, dispatchContactForm] =
    useContext(ContactContext)

  // A unique index value open state of search bar, well beyond the uppermost index value for NavMenu items
  const searchIndex = 99
  const favoritesIndex = 98
  const recentlyViewedIndex = 97

  // Detect screen width - Nav groups rendered in NavigationGroupComp on desktop and FooterAccordion on mobile
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)")
    setMobileView(mediaQuery.matches || false)
    mediaQuery.addEventListener("change", e => {
      const mobileViewOn = e.matches
      setMobileView(mobileViewOn)
    })
  }, [])

  useEffect(() => {
    if (language === "es" && headerData) {
      setData(headerData?.esHeaderMenu)
    } else {
      setData(headerData?.headerMenu)
    }
  }, [language])

  // Styles
  const hamburgerMenuLine = tw`h-[3px] w-7 bg-black transform transition duration-500 ease-out`

  // Disable page scroll when menu is open
  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [toggleMenu])

  const openLanguagePopover = () => setOpenPopover(OPEN_POPOVER.LANGUAGE)

  const [openPopover, setOpenPopover] = useState<OPEN_POPOVER>(null)
  const closePopover = () => setOpenPopover(null)

  const toggleOpenLanguage = () =>
    openPopover === OPEN_POPOVER.LANGUAGE
      ? closePopover()
      : openLanguagePopover()

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const toggleRoute = () => {
    if (typeof window === "undefined") return
    const url = new URL(window.location.href)
    const path = url.pathname.split("/").filter(String)
    const newLanguage = language == "es" ? "" : "es"

    navigate(
      `/${newLanguage && newLanguage + "/"}${path
        .splice(path.indexOf(language) + 1)
        .join("/")}`,
    )
  }

  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const LOCAL_STORAGE_KEY = "visitedBefore"

  const [isVisible, setIsVisible] = useState(() => {
    if (typeof window !== "undefined") {
      const visitedBefore = localStorage.getItem(LOCAL_STORAGE_KEY)
      return visitedBefore ? false : true
    }
    return true
  })

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "true")

    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setTooltipVisible(false)
      setIsVisible(false)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const { height, width } = useViewportSize()
  const isMobile = width < 1024

  return (
    <>
      <section css={[tw`hidden lg:(block)`]}>
        <Banner />
      </section>
      <header
        css={[
          tw`sticky top-0 w-full h-[70px] bg-gray-100 shadow-4 md:(bg-white)`,
          "z-index: 997;",
        ]}
        aria-label="site"
        ref={navMenuRef}
      >
        <div css={[tw`max-w-[1440px] mx-auto flex justify-between w-full`]}>
          <nav
            css={[
              tw`inline-grid w-full max-h-[70px] min-h-[70px]`,
              tw`lg:(w-auto)`,
              // Make grid columns shrink-to-fit content
              "grid-template-columns: auto auto;",
            ]}
            aria-label="primary"
          >
            <div
              onClick={() => setIsVisible(true)} // Show the text and icon when clicking
              onMouseEnter={() => !isMobile && setIsVisible(true)}
              onMouseLeave={() => !isTooltipVisible && setIsVisible(false)}
              onMouseOver={() => !isMobile && setIsVisible(true)}
              css={[
                tw`pl-5 z-40 my-auto flex-row lg:(pl-10)`,
                isTooltipVisible && isMobile ? tw`z-[42]` : tw`z-[40]`,
              ]}
            >
              <div>
                <Link
                  to={language === "es" ? "/es/" : "/"}
                  target="_self"
                  css={[
                    tw`col-span-1 mx-0 h-auto !py-4 items-center flex justify-between border border-transparent`,
                    tw`sm:(ml-0)`,
                    tw`md:(mx-0 mr-5)`,
                    tw`lg:(py-0 my-0)`,
                    tw`focus-visible:(outline-red-50)`,
                  ]}
                >
                  <motion.div
                    initial={{ y: 1 }}
                    animate={isVisible ? { y: -7 } : { y: 1 }}
                    transition={{ duration: 0.1 }}
                  >
                    <ToyotaLogo fill={"#000"} css={[tw`h-full`]} />
                  </motion.div>
                </Link>
              </div>
              <div css={[tw`flex flex-nowrap items-center`]}>
                <AnimatePresence>
                  {isVisible && (
                    <motion.div
                      initial={{ opacity: 1, y: -15 }}
                      animate={{ opacity: 1, y: -15 }}
                      exit={{ opacity: 0, y: -15 }}
                      transition={{ duration: 0.1 }}
                      css={[
                        tw`text-[10px] text-gray-700 absolute border-b border-dotted border-b-gray-600`,
                      ]}
                    >
                      <div
                        css={[tw`cursor-pointer`]}
                        onClick={() => setTooltipVisible(!isTooltipVisible)}
                      >
                        {_("Southeast")}
                        <Icon.InfoOutlined
                          color="gray-700"
                          css={[tw`inline-flex ml-1 mb-0.5 h-[8px] w-[8px]`]}
                        />
                      </div>
                      {isTooltipVisible && (
                        <Tooltip fromToyotaLogo color="black">
                          <span>
                            {_(
                              "Explore Toyota Vehicles, Accessories, Parts and Services across Alabama, Florida, Georgia, North Carolina and South Carolina.",
                            )}{" "}
                          </span>
                          <Link
                            to="/about-us/"
                            css={tw`inline underline`}
                            onClick={() => {
                              trackTealEvent({
                                tealium_event: "nav_click",
                                click_text: `top nav:Learn More`,
                              })
                            }}
                            analytics-id={`Learn More:top nav:`}
                          >
                            {_("Learn More")}
                          </Link>
                        </Tooltip>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            {/* Search icon mobile */}
            <div
              css={[
                tw`col-span-1 flex h-[70px] justify-end items-center p-1 focus-visible:(outline-red-50)`,
                tw`lg:(hidden)`,
              ]}
            >
              <Link
                css={[
                  tw`p-1 focus-visible:(outline-red-50)`,
                  tw`lg:(hidden)`,
                  toggleMenu === true && tw`hidden`,
                ]}
                to="/search"
              >
                <Icon.Search
                  color={`${open === 99 ? "red-400" : "gray-700"}`}
                  css={[tw`h-6`]}
                />
              </Link>
              <Link
                animated
                animatedThin
                onClick={() => {
                  toggleOpenLanguage()
                  trackTealEvent({ tealium_event: "language_selector" })
                }}
                css={[
                  tw`flex justify-items-center items-center`,
                  tw`md:(py-0 after:(left-8 right-0 w-auto))`,
                  toggleMenu === false && tw`hidden`,
                ]}
                target="_self"
              >
                <Icon.Language
                  color="red-400"
                  css={[tw`inline-flex w-4 mx-2`, tw`lg:hidden`]}
                />
                <span
                  css={[
                    tw`text-sm`,
                    css`
                      &:after {
                        content: "";
                        transform: scaleX(0);
                        ${tw`absolute w-full rounded-full h-0.5 bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
                      }
                      button:hover &:after a:hover &:after {
                        transform: scaleX(1);
                        ${tw`origin-bottom-left`}
                      }
                    `,
                  ]}
                >
                  {language === "en" ? "Español" : "English"}
                </span>
              </Link>
              <Popover
                open={openPopover === OPEN_POPOVER.LANGUAGE}
                origin={POPOVER_ORIGINS.BOTTOM_RIGHT}
                onClose={closePopover}
                css={[tw`bg-white w-[24em] p-8 pt-6`, `z-index: 9999;`]}
              >
                <div css={[tw`bg-white`, `z-index: 99999;`]}>
                  <h3 css={[tw`text-lg`]}>{_("Set Your Language")}</h3>
                  <div css={[tw`flex flex-row justify-center mt-4`]}>
                    <Button primary onClick={() => toggleRoute()}>
                      {language === "en"
                        ? "Cambiar a Español"
                        : "Change to English"}
                    </Button>
                  </div>
                </div>
              </Popover>
              {/* Hamburger icon */}
              <button
                css={[
                  tw`flex flex-col items-end justify-center w-12 h-12 focus:outline-none ml-2 mr-6`,
                  tw`sm:(mr-9)`,
                  tw`lg:(hidden)`,
                  // Remove blue highlight on click (chrome)
                  "-webkit-tap-highlight-color: transparent;",
                ]}
                onClick={() => {
                  setToggleMenu(!toggleMenu)
                  setIsVisible(!toggleMenu)
                }}
              >
                <span
                  aria-hidden="true"
                  css={[
                    hamburgerMenuLine,
                    toggleMenu
                      ? tw`rotate-45 translate-y-[3px]`
                      : tw`-translate-y-1`,
                  ]}
                ></span>
                <span
                  aria-hidden="true"
                  css={[
                    hamburgerMenuLine,
                    tw`duration-75`,
                    toggleMenu && tw`opacity-0`,
                  ]}
                ></span>
                <span
                  aria-hidden="true"
                  css={[
                    hamburgerMenuLine,
                    toggleMenu
                      ? tw`-rotate-45 translate-y-[-3px]`
                      : tw`translate-y-1`,
                  ]}
                ></span>
              </button>
            </div>
            {/* Menu content */}
            <NavMenu
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
              open={open}
              setOpen={setOpen}
              mobileView={mobileView}
              items={data?.menuItems}
              css={[tw`col-span-2`, tw`lg:(col-span-1)`]}
            />
          </nav>
          {/* Displays only on md-up */}
          <div
            css={[
              tw`hidden`,
              tw`lg:(flex items-center gap-3 justify-end min-w-[180px])`,
            ]}
          >
            <button
              css={[tw`p-1 focus-visible:(outline-red-50)`]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:Recently Viewed Vehicles`,
                })
                open === recentlyViewedIndex
                  ? setOpen(null)
                  : setOpen(recentlyViewedIndex)
              }}
              analytics-id={`history:top nav:`}
            >
              <Icon.History
                color={open === 97 ? "red-500" : "gray-700"}
                css={[tw`h-6`]}
              />
            </button>
            <div css={[tw`relative flex items-center`]}>
              <button
                css={[tw`flex items-center p-1 focus-visible:(outline-red-50)`]}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "nav_click",
                    click_text: `top nav:Favorites`,
                  })
                  open === favoritesIndex
                    ? (setOpen(null), favDispatch(setShouldShowTooltip(false)))
                    : (setOpen(favoritesIndex),
                      favDispatch(setShouldShowTooltip(false)))
                }}
                analytics-id={`favorites:top nav:`}
              >
                <Icon.Heart
                  color={open === 98 ? "red-500" : "gray-700"}
                  css={[tw`h-6`]}
                  filled
                />{" "}
              </button>

              <FavoriteTooltip />
            </div>
            {/* Reset searchEntry value only to avoid refeshing search results */}
            <button
              css={[tw`p-1 pr-10 focus-visible:(outline-red-50)`]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:Search`,
                })
                open === searchIndex ? setOpen(null) : setOpen(searchIndex)
                dispatch({ type: "SET_SEARCH_ENTRY", payload: "" })
              }}
              analytics-id={`search:top nav:`}
            >
              <Icon.Search
                color={open === 99 ? "red-500" : "gray-700"}
                css={[tw`h-6`]}
              />
            </button>
            {/* Desktop Search Menu, lives in HeaderNavigation */}
          </div>
        </div>
        {open === 99 ? (
          <SearchGlobalDesktop
            open={open}
            setOpen={setOpen}
            mobileView={mobileView}
            searchIndex={searchIndex}
          />
        ) : null}
        {open === 98 ? (
          <Favorites open={open === 98} mobileView={mobileView} />
        ) : null}
        {open === 97 ? <RecentlyViewed open={open === 97} /> : null}
        <FavoritesContactDealer
          modalIsOpen={isFavoritesContactDealerModalOpen}
          setModalIsOpen={open => {
            if (open) {
              dispatchContactForm(openFavoritesContactDealerModal())
            } else {
              dispatchContactForm(closeFavoritesContactDealerModal())
            }
          }}
        />
      </header>

      {/* <div id="content"></div> */}
    </>
  )
}

export default HeaderNavigation
